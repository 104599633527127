<div class="logo">
    <a class="">
        <div class="logo-img">
            <img src="./assets/img/logo_blanc.png" alt="logo" />
        </div>
    </a>
</div>

<div class="sidebar-wrapper">

    <div class="user">

        <div class="user-info" *ngIf="user">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    {{user.firstname}} {{user.lastname}}
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item">
                        <a href="javascript:void(0)" routerLink="/profil" class="nav-link">
                            <i class="material-icons">face</i>
                            <span class="sidebar-normal">Mon profil</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" (click)="signout()" class="nav-link">
                            <i class="material-icons">logout</i>
                            <span class="sidebar-normal">Deconnexion</span>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>

    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link' && (menuitem.roles.indexOf(userType) > -1)" class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title}}</p>
            </a>
        </li>
        <ng-container *ngIf="user">
            <li class="nav-item" *ngIf="user.role === 'nurse' ">
                <a href="https://libermedical.fr/BSI_FORMULAIRE.html" target="_blank" class="nav-link">
                    <i class="material-icons">link</i>
                    Remplir une demande de BSI
                </a>
            </li>
        </ng-container>

    </ul>
    <ng-container *ngIf="user">
        <div class="conseil"
            *ngIf="user.role === 'nurse' || user.role === 'opticien'|| user.role === 'medecin'">
            <table>
                <tr>
                    <td>
                        &nbsp;
                        <i class="material-icons">face</i>
                        &nbsp;
                    </td>
                    <td align="center" class="spetd">
                        <span class="bold">Mon gestionnaire</span>
                        <br /> {{user.manager_phone}}
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="user">
        <div *ngIf="user.appointment && (user.role === 'nurse' || user.role === 'nurseSub' || user.role === 'opticien' || user.role === 'medecin')"
            class="rdv text-info">
            <table>
                <tr>
                    <td align="center">
                        Mon prochain rendez-vous est le {{user.appointment | date: 'dd/MM/yyyy'}} à
                        {{user.appointment | date: 'HH:mm'}}
                    </td>
                </tr>
            </table>
        </div>
        <div class="text-center" >
            <ul class="nav">
                <li class="nav-item">
                    <a href="https://statuts.libermedical.fr"  class="nav-link" target="_blank" style="color:  #91c602;">Disponibilité des services</a>
                </li>
                <li class="nav-item">
                    <span class="version">Version 3.0.4</span>
                </li>
            </ul>
        </div>
    </ng-container>
</div>